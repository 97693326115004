<template>
<div class='page'>
 
  <industrialbanner :IndustrialbannerData="IndustrialbannerData"></industrialbanner>
  <myhead :hbg="hbg"></myhead>
  <bread :bbg="bbg"></bread> 
 <industrieshead :industriesheadLists="industriesheadLists"></industrieshead>
   <applications2 :ApplicationsLists="ApplicationsLists"></applications2>
      <capabilities :CapabilitiesList="CapabilitiesList"></capabilities>
  <customers></customers>
 
</div>
</template>
<script>
import industrialbanner from '../../components/Industrialbanner.vue'
import bread from '../../components/bread.vue'
import myhead from "../../components/myHead.vue";
import industrieshead from "../../components/industrieshead.vue";
import applications2 from "../../components/Applications2.vue";
import customers from "../../components/Customers.vue"
import capabilities from "../../components/Capabilities.vue"
export default {
    components: {
    myhead,
    industrieshead,
    applications2,
    customers,
    capabilities,
    industrialbanner,
    bread
  },
  data() {
    return {
          CapabilitiesList:{
        H1:'COMMUICATION POST-PROCESSING CAPABILITIES',
        lists:[{ 
            title:'Anodizing',
        },{
            title:'Sandblasting',
        },{
            title:'Painting',
        },{
            title:'Insert Installation',
        }],
        img: require('../../assets/img/communicationsdx1.png')
    },
        IndustrialbannerData:{
				H1:'COMMUNICATIONS',
				info:'We understand the demands and ever changing landscape of the communications industry and work with you to ensure they are met and exceeded.',
				background:'#0B090A',
				img:require('../../assets/img/communicationsbanner.png'), 
		
			},
      industriesheadLists: {
        ref: "Communications",
        H1: "COMMUNICATIONS",
        info: `Communication is the most important part of our daily life. There are more and communication products appeared, especially when Internet come into our life, for example, smartphones, multifunctional audio, portable laptop and so on.<br>HLH totally understand how important communication industry is and also know much about communication products. We worked with top companies in this industry and been highly praised. That's why we have the faith of offering you best solution.`,
        imgs: [
          {
            src: require("../../assets/img/communications1.png")
          }
        ],
        h2: "Top Communication Applications",
        data: [
          {
            content:   "Housings and enclosures"
          },
          {
            content:    "Precision parts and prototypes"
          },
          {
            content:  "Low volume production"
          },
          {
            content: "Smart devices"
          } 
        ]
      },
          hbg:{
        bg:'#ffffff'

    },
    bbg:{
 bg:'#ffffff',
 color:'#000000'
    },
      ApplicationsLists: {
        H1: "WHY COMMUNICATION COMPANIES CHOOSE HLH？",
        data: [
          {
            img: require("../../assets/img/communicationsmdoel1.png"),
            title: "Low-Volume Production",
            info: "Get low-volume production parts in as fast as 2 day to streamline your supply chain once before and after products are launched to market."
          },
          {
            img: require("../../assets/img/communicationsmdoel2.png"),
            title: "Interactive Design Analysis",
            info: "Make critical design adjustments that save development time and cost with design for manufacturability feedback on every quote."
          },
          {
            img: require("../../assets/img/aerospaceUAV1model2.png"),
            title: "Industry Leading Quality",
            info: "Quality services include material certifications, certificates of conformity, advanced inspection reports and more."
          }
        ]
      }
    };
  },
  
  methods: {},
  mounted() {},
  created() {},
  props: {},
  watch: {},

};
</script>
<style scoped lang="scss">
.page {
}
.bannerBg {
  width: 100%;
  height: 620px;
  background: red;
}
</style>